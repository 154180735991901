export default [
  {
    header: 'Rechtliches',
    items: [
      {
        path: '/impressum',
        label: 'Impressum',
      },
      {
        path: '/datenschutzerklaerung',
        label: 'Datenschutzerklärung',
      },
    ],
  },
];
