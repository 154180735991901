/** @jsx jsx */
import {jsx, Container, Flex, Button, Link as A} from 'theme-ui';
import { Link as ScrollLink } from 'react-scroll';
import Logo from 'components/logo';

import { DrawerProvider } from 'contexts/drawer/drawer.provider';
import MobileDrawer from './mobileDrawer';
import React from "react";
import { Link } from "gatsby";

export default function FrontpageHeader({ className }) {
  return (
    <DrawerProvider>
      <header sx={styles.header} className={className}>
        <Container sx={styles.container}>
          <Logo />

          <Flex as="nav" sx={styles.nav}>
            <Link to={'/wochenaufenthalter'}
                  sx={styles.nav.navLink}
            >
              Wochenaufenthalter
            </Link>
            <A
                sx={styles.nav.navLink}
                href="https://nettolohnrechnerapp.netlify.app/login"
                target="_blank"
            >
              Login
            </A>
            <A
                href="https://buy.stripe.com/7sI3fLc1ycJdblKeUU"
                target="_blank"
            >
              <Button
                  variant="primary"
                  aria-label="btn"
                  sx={styles.headerBtn}
              >
                Jetzt Berechnung starten
              </Button>
            </A>
          </Flex>

          <MobileDrawer isFrontpage={true} />
        </Container>
      </header>
    </DrawerProvider>
  );
}

const styles = {
  headerBtn: {
    fontSize: '16px',
    fontWeight: 700,
    display: ['none', null, null, null, 'inline-block'],
  },
  header: {
    color: 'text_white',
    fontWeight: 'normal',
    py: '25px',
    width: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    backgroundColor: 'transparent',
    transition: 'all 0.4s ease',
    '&.sticky': {
      backgroundColor: 'background',
      color: 'text',
      py: '15px',
      boxShadow: '0 1px 2px rgba(0, 0, 0, 0.06)',
    },
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '1430px',
    '@media(max-width:1440px)': {
      maxWidth: '1230px',
    },
    '@media screen and (max-width: 991px)': {
      justifyContent: 'space-between',
    },
  },
  nav: {
    ml: 'auto',
    '@media screen and (max-width: 991px)': {
      display: 'none',
    },
    navLink: {
      fontSize: '16px',
      color: '#02073E',
      fontWeight: '400',
      cursor: 'pointer',
      textDecoration: 'none',
      lineHeight: '3.2',
      mr: '36px',
      transition: '500ms',
      ':last-child': {
        mr: '0',
      },
      '&:hover, &.active': {
        color: 'primary',
      },
    },
  },
};
