import React, { useContext } from 'react';
import {Button, Box, Link as A} from 'theme-ui';
import { Scrollbars } from 'react-custom-scrollbars';
import Drawer from 'components/drawer';
import { DrawerContext } from 'contexts/drawer/drawer.context';
import { IoMdClose, IoMdMenu } from 'react-icons/io';
import Logo from 'components/logo';
import LogoDark from 'assets/logo.svg';
import { Link as ScrollLink } from "react-scroll";

const MobileDrawer = ({ isFrontpage = false }) => {
  const { state, dispatch } = useContext(DrawerContext);

  // Toggle drawer
  const toggleHandler = React.useCallback(() => {
    dispatch({
      type: 'TOGGLE',
    });
  }, [dispatch]);

  return (
    <Drawer
      width="320px"
      drawerHandler={
        <Box sx={styles.handler}>
          <IoMdMenu size="22px" />
        </Box>
      }
      open={state.isOpen}
      toggleHandler={toggleHandler}
      closeButton={<IoMdClose size="24px" color="#02073E" />}
      drawerStyle={styles.drawer}
      closeBtnStyle={styles.close}
    >
      <Scrollbars autoHide>
        <Box sx={styles.content}>
          <Logo src={LogoDark} />
          <Box sx={styles.menu}>
            {isFrontpage &&
                <A
                    href="https://buy.stripe.com/7sI3fLc1ycJdblKeUU"
                    target="_blank"
                >
                  <Button variant="primary" sx={styles.button}>
                    Jetzt Berechnung starten
                  </Button>
                </A>
            }
            {isFrontpage &&
              <A
                sx={styles.menu.link}
                href="/wochenaufenthalter"
                >
                Wochenaufenthalter
              </A>
            }
            {!isFrontpage &&
                <A
                    sx={styles.menu.link}
                    href="/"
                >
                  Grenzgänger
                </A>
            }
            <A
                sx={styles.menu.link}
                href="https://app.nettolohnrechner.ch/login"
                target="_blank"
            >
              Login
            </A>
          </Box>
        </Box>
      </Scrollbars>
    </Drawer>
  );
};

const styles = {
  handler: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: '0',
    width: '26px',

    '@media screen and (min-width: 992px)': {
      display: 'none',
    },
  },

  drawer: {
    width: '100%',
    height: '100%',
    background: 'linear-gradient(180deg, #e3dedd 0%, #F6F6FF 100%)',
  },

  close: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '30px',
    right: '30px',
    zIndex: '1',
  },

  content: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    pt: '30px',
    pb: '40px',
    px: '30px',
  },

  menu: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '30px',

    link: {
      fontSize: '15px',
      fontWeight: '500',
      color: '#02073E',
      marginTop: '10px',
      py: '5px',
      cursor: 'pointer',
      textDecoration: 'none',
    },
  },

  menuHeader: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    mt: '0',
  },

  button: {
    fontSize: '15px',
    fw: '700',
    height: '48px',
    borderRadius: '3px',
    cursor: 'pointer',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    py: '0',
    color: '#fff',
  },
};

export default MobileDrawer;
