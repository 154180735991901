/** @jsx jsx */
import { jsx, Image, Box } from 'theme-ui';
import { Link } from 'components/link';
import LogoDark from 'assets/logo_confinio.png';

export default function Logo() {
  return (
    <Link
      path="/"
      sx={{
        variant: 'links.logo',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        width: '175px',
        overflow: 'hidden',
        margin: '0'
      }}
    >
      <Image src={LogoDark} sx={{
          width: '50%'
      }} alt="Confinio GmbH" />
    </Link>
  );
}
